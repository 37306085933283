import React from "react";
import styled from "styled-components";

// Define the button with the specified styles
const StyledButton = styled.button`
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: ${({ disabled }) => (disabled ? "#EBF6FF" : "#1F93EF")};
  color: ${({ disabled }) => (disabled ? "##16161E52" : "#FFF")};
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  border: none;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "")};

  &:hover {
    background: ${({ disabled }) => (disabled ? "#EBF6FF" : "#4a90e2")};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(100, 100, 255, 0.3);
  }
`;

// Define the Button component
const PrimaryButton = ({ children, onClick, disabled, fullWidth, ...props }) => (
  <StyledButton onClick={!disabled ? onClick : undefined} disabled={disabled} fullWidth={fullWidth} {...props}>
    {children}
  </StyledButton>
);

export default PrimaryButton;
