import React, { useEffect, useRef, useState } from "react";
import { Avatar, Checkbox, Select, Tooltip } from "antd";
import { SelectMember } from "../../../pages/BRSRNew/BulkAssignQuestions/SelectMember/SelectMember";
import { getInitials, isEmptyObject, updateOrCreate, updateOrReplace } from "../../../helper/genericFuntions";
import PrimaryButton from "../../Buttons/PrimaryButton/PrimaryButton";
import { removeOnlyFacilityIdObjects } from "../../../helper/Utils/utilitiyFunctions";
import CustomTooltip from "../../Helper/CustomTooltip";
import { indicatorMap, principlesMap, sectionMap } from "../../../helper/constant";
import CustomModal from "../../Modal/Modal";

import "./BRSRAccordion.scss";
import "../common.scss";
import "../../../App.scss";

const BRSRAccordion = ({
  facFilterTwo,
  assignedQuest,
  wholeQuestion,
  colors,
  section,
  navigate,
  _questionId,
  questionSelected,
  setQuestionSelected,
  questionSelectedDetail,
  setQuestionSelectedDetail,
  showCheckbox,
  index,
  setError,
  setDepartmentVal,
  setShowModal,
  facFilter,
  setFacFilter,
  resultDepartment,
  userFac,
  openSnackbar,
  assignQuestion,
  subtitle,
  title,
  isOpen,
  onToggle,
  department,
  questionType,
  contributors,
  assignedContributors,
  isAssigned,
  questionId,
  reportId,
  setQuestionId,
  reportFyYear,
  indicator,
  principle,
  facilityAssignment,
  isGlobalAssignBulk
}) => {
  const contentRef = useRef(null);

  const [members, setMembers] = useState({});
  const [memberArr, setMemberArr] = useState([]);
  const [checkDisabled, setCheckDisabled] = useState([]);
  const [manualChecked, setManualChecked] = useState(false);
  const [shouldModalAppear, setShouldModalAppear] = useState(false);
  const [showModalSingle, setShowModalSingle] = useState(false);
  const [checkedModalObject, setCheckedModalObject] = useState({});

  const handleChecked = (e, fac, user, role, cannotSkip) => {
    if (shouldModalAppear && cannotSkip) {
      setShowModalSingle(true);
      setCheckedModalObject({ e, fac, user, role });
      return;
    }
    setManualChecked(true);
    let obj = { ...members };
    let arr = [];

    if (facilityAssignment === "single") {
      Object.keys(obj).forEach((key) => {
        const [existingUser, existingFac, existingRole] = key.split(".");
        if (existingRole === role) {
          obj[key] = false;
        }
      });
      obj[`${user}.${fac}.${role}`] = e.target.checked;
      setMembers(obj);
    } else {
      obj[`${user}.${fac}.${role}`] = e.target.checked;
      setMembers({ ...members, ...obj });
    }
    if (role === "maker") {
      if (e.target.checked) {
        if (facilityAssignment === "single") {
          arr = updateOrReplace(memberArr, fac, { makerId: user });
          setMemberArr(arr);
        } else {
          arr = updateOrCreate(memberArr, fac, { makerId: user });
          setMemberArr(arr);
        }

        let contr;

        let updatedCheckDisabled = checkDisabled.filter((cont) => !(cont.role === role));
        contr = contributors?.filter((cont) => cont.role === role && cont.facilityId === fac && cont._id !== user);

        setCheckDisabled([...updatedCheckDisabled, ...contr]);
      } else {
        let newMembers = memberArr.map((obj) => {
          if (obj.facilityId === fac && obj.makerId === user) {
            const newObj = { ...obj };
            delete newObj["makerId"];
            return newObj;
          }
          return obj;
        });
        newMembers = removeOnlyFacilityIdObjects(newMembers);
        setMemberArr(newMembers);
        let contr;

        contr = checkDisabled.filter((cont) => !(cont.role === role && cont.facilityId === fac && cont._id !== user));
        setCheckDisabled(contr);
      }
    } else if (role === "reviewer/approver") {
      if (e.target.checked) {
        if (facilityAssignment === "single") {
          arr = updateOrReplace(memberArr, fac, { reviewerApproverId: user });
          setMemberArr(arr);
        } else {
          arr = updateOrCreate(memberArr, fac, { reviewerApproverId: user });
          setMemberArr(arr);
        }

        let contr;

        let updatedCheckDisabled = checkDisabled.filter((cont) => !(cont.role === role));
        contr = contributors?.filter((cont) => cont.role === role && cont.facilityId === fac && cont._id !== user);

        setCheckDisabled([...updatedCheckDisabled, ...contr]);
      } else {
        let newMembers = memberArr.map((obj) => {
          if (obj.facilityId === fac && obj.reviewerApproverId === user) {
            const newObj = { ...obj };
            delete newObj["reviewerApproverId"];
            return newObj;
          }
          return obj;
        });
        newMembers = removeOnlyFacilityIdObjects(newMembers);
        setMemberArr(newMembers);
        let contr;

        contr = checkDisabled.filter((cont) => !(cont.role === role && cont.facilityId === fac && cont._id !== user));

        setCheckDisabled(contr);
      }
    }
  };

  // console.log("checkDisabled====", checkDisabled);

  const handleQuestionSelected = (e, title, qId, department, subtitle, contributors, facilityAssignment) => {
    let obj = {};
    let objDetail = {
      id: qId,
      department,
      subtitle,
      title,
      contributors,
      facilityAssignment
    };

    let arr = [...questionSelectedDetail];
    obj[qId] = e.target.checked;
    setQuestionSelected({ ...questionSelected, ...obj });

    if (e.target.checked) {
      arr.push(objDetail);
      setQuestionSelectedDetail(arr);
    } else {
      arr = arr.filter((item) => item.id !== qId);
      setQuestionSelectedDetail(arr);
    }
  };


  useEffect(() => {
    let obj = {};
    let arr = [];
    let memberObj = {};
    if (!isEmptyObject(assignedContributors[0]) && facilityAssignment === "single") {
      setShouldModalAppear(true);
    }
    assignedContributors?.forEach((item) => {
      item.facility?.forEach((facObj) => {
        obj[`${item._id}.${facObj.facilityId}.${item.role}`] = true;
        memberObj = { ...memberObj, ...obj };
        if (item.role === "maker") {
          arr = updateOrCreate(memberArr, facObj.facilityId, { makerId: item._id });
          setMemberArr(arr);

          let contr = contributors?.filter(
            (cont) => cont.role === item.role && cont.facilityId === facObj.facilityId && cont._id !== item._id
          );
          if (contr) {
            setCheckDisabled([...checkDisabled, ...contr]);
          }
        } else if (item.role === "reviewer/approver") {
          arr = updateOrCreate(memberArr, facObj.facilityId, { reviewerApproverId: item._id });
          setMemberArr(arr);

          let contr = contributors?.filter(
            (cont) => cont.role === item.role && cont.facilityId === facObj.facilityId && cont._id !== item._id
          );
          if (contr) {
            setCheckDisabled([...checkDisabled, ...contr]);
          }
        }
      });
      setMembers(memberObj);
    });
  }, [assignedContributors]);

  const handleSubmit = async () => {
    let postObj = {
      questionName: questionId,
      reportId,
      contributors: memberArr
    };
    try {
      const response = await assignQuestion(postObj);
      if (response.error) {
        setError(response.error.data?.message);
      } else {
        openSnackbar(response.data?.message, "success");
        setQuestionSelected({});
        setQuestionSelectedDetail([]);
        setMembers({});
        setMemberArr([]);
        onToggle();
        // postAuditLogData({
        //   action: "CREATE",
        //   message: `${user.name} created new simulation ${simulationsModalValues.name}`,
        //   tab: "Simulation",
        //   facility: "",
        //   organization: orgData.data?.data._id,
        //   user: userId._id,
        //   ipAddress: "10.10.0.1"
        // });
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || "An error occurred";
      openSnackbar(errorMessage, "error");
    }
  };

  const handleDepartmentChange = (value, qId) => {
    setQuestionId(qId);
    setDepartmentVal(value);
    setShowModal(true);
  };

  const changeContributor = () => {
    setShowModalSingle(false);
    setShouldModalAppear(false);
    handleChecked(
      checkedModalObject.e,
      checkedModalObject.fac,
      checkedModalObject.user,
      checkedModalObject.role,
      false
    );
  };

  return (
    <>
      {
        <CustomModal modalOpen={showModalSingle} setModalOpen={setShowModalSingle} outSideClose={false}>
          <div className="d-flex gap-3">
            <div>
              <img src="/images/icons/warning.svg" alt="icon" />
            </div>

            <div style={{ maxWidth: "390px" }}>
              <div>
                <p className="modal-title-plan text-start" style={{ fontSize: "16px" }}>
                  Changing contributors for single facility question?
                </p>
                <div className="single-fac">
                  <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="4" cy="4" r="4" fill="#FFA11E" />
                  </svg>
                  <p>Single Facility question</p>
                </div>
                <p className="sub-heading-text" style={{ fontSize: "14px" }}>
                  Upon assigning new contributors for single facility question, All assigned and answered questions of
                  the previous contributors will be removed.
                </p>
              </div>

              <div className="d-flex gap-4 mt-3">
                <button type="button" className="btn btn-outline-success" onClick={() => setShowModalSingle(false)}>
                  Cancel
                </button>
                <button type="button" className="btn btn-success" onClick={changeContributor}>
                  Yes, Change
                </button>
              </div>
            </div>

            <div className="close-icon-placement" style={{}} onClick={() => setShowModalSingle(false)}>
              <img src="/images/icons/cross-icon.svg" alt="icon" className="cursor-pointer x-icon" />
            </div>
          </div>
        </CustomModal>
      }
      <div className={`accordion`} style={{ borderTop: `${index !== 0 && "0px"}` }}>
        <div className={`${questionSelected[_questionId] ? "blue-bg" : ""}`}>
          <div className="accordion__header">
            <div>
              <div className="d-flex gap-2 checkbox-start">
                {showCheckbox && userFac?.reportRole === "Admin" && !assignedQuest && (
                  <Checkbox
                    disabled={false}
                    checked={questionSelected[_questionId]}
                    onChange={(e) =>
                      handleQuestionSelected(
                        e,
                        title,
                        _questionId,
                        department,
                        subtitle,
                        assignedContributors,
                        facilityAssignment
                      )
                    }
                  />
                )}
                <div className="d-flex flex-column gap-1">
                  <div className="d-flex gap-1 align-items-center">
                    <p className="sub-heading-text">{sectionMap[section]}</p>
                    <p className="sub-heading-text">
                      / {subtitle.includes("principle") ? principlesMap[subtitle] : subtitle}
                    </p>
                    {indicator && <p className="sub-heading-text">/ {indicatorMap[indicator]}</p>}
                    <div className="single-multi-pill">
                      <p className="sub-heading-text">{facilityAssignment === "single" ? "Single" : "Multiple"}</p>
                    </div>
                  </div>
                  <p className="card-type-title" style={{ fontSize: "14px", maxWidth: "790px" }}>
                    {title}
                  </p>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center gap-2">
              {assignedContributors?.every((obj) => Object.keys(obj).length > 0) && (
                <div>
                  <Avatar.Group
                    max={{
                      // count: 2,
                      style: { color: "#f56a00", backgroundColor: "#fde3cf" }
                    }}
                  >
                    {assignedContributors?.map((item, index) => (
                      <Tooltip
                        key={index}
                        // title={item.name}
                        title={<CustomTooltip item={item} />}
                        placement="topRight"
                      >
                        <Avatar style={{ backgroundColor: colors[index % colors.length] }}>
                          {getInitials(item.name)}
                        </Avatar>
                      </Tooltip>
                    ))}
                  </Avatar.Group>
                </div>
              )}
              {!assignedQuest && !isGlobalAssignBulk ? (
                <Select
                  placeholder={"CS"}
                  showSearch
                  name="department"
                  size="small"
                  value={department}
                  // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                  onChange={(value) => handleDepartmentChange(value, _questionId)}
                  className="br-8 gray-select"
                  style={{ minWidth: "100px" }}
                >
                  {resultDepartment?.data?.data?.map((item, index) => (
                    <Select.Option key={index + "i"} value={item.name}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              ) : (
                <div className="gray-pill">{department}</div>
              )}
              {questionType === "table" ? (
                <img src="/images/icons/table.svg" alt="" />
              ) : (
                <img src="/images/icons/text.svg" alt="" />
              )}
              {!assignedQuest ? (
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <circle opacity="0.1" cx="12" cy="12" r="9" fill={isAssigned ? "#38CB24" : "#F6F9FA"} />
                  <circle cx="12" cy="12" r="4" fill={isAssigned ? "#38CB24" : "#E2E2EA"} />
                </svg>
              ) : wholeQuestion.isAccepted ? (
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <circle opacity="0.1" cx="12" cy="12" r="9" fill="#38CB24" />
                  <circle cx="12" cy="12" r="4" fill="#38CB24" />
                </svg>
              ) : wholeQuestion.isApproved ? (
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <circle opacity="0.1" cx="12" cy="12" r="9" fill="#50CFAB" />
                  <circle cx="12" cy="12" r="4" fill="#50CFAB" />
                </svg>
              ) : wholeQuestion.isRejected ? (
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <circle opacity="0.1" cx="12" cy="12" r="9" fill="#F400A1" />
                  <circle opacity="0.8" cx="12" cy="12" r="4" fill="#F400A1" />
                </svg>
              ) : wholeQuestion.isAnswered ? (
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <circle opacity="0.1" cx="12" cy="12" r="9" fill="#1C72FF" />
                  <circle cx="12" cy="12" r="4" fill="#1C72FF" />
                </svg>
              ) : wholeQuestion.isPendingApproval ? (
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <circle opacity="0.1" cx="12" cy="12" r="9" fill="#FFA11E" />
                  <circle cx="12" cy="12" r="4" fill="#FFA11E" />
                </svg>
              ) : wholeQuestion.isRejected ? (
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <g style={{ mixBlendMode: "multiply" }} opacity="0.05">
                    <circle cx="12" cy="12" r="9" fill="#ad1c56" />
                  </g>
                  <circle cx="12" cy="12" r="4" fill="#ad1c56" />
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <circle opacity="0.1" cx="12" cy="12" r="9" fill="#CE7B00" />
                  <circle cx="12" cy="12" r="4" fill="#CE7B00" />
                </svg>
              )}
              {!assignedQuest && !wholeQuestion.isAccepted && !isGlobalAssignBulk && (
                <span className="blue-text" onClick={onToggle}>
                  {isAssigned ? "Edit contributors" : "Assign"}
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                    <path
                      d="M12 8.75L9 11.75L6 8.75"
                      stroke="#1F93EF"
                      stroke-width="1.4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              )}
              {userFac?.reportRole === "Admin" ? (
                <p
                  className="sub-heading-text cursor-pointer"
                  style={{ fontSize: "16px" }}
                  onClick={() =>
                    navigate("/approvequestions", {
                      state: {
                        qId: _questionId,
                        section,
                        reportId,
                        facId: facFilterTwo,
                        subSection: subtitle,
                        reportFyYear: reportFyYear,
                        indicator: indicator,
                        principle: principle,
                        isGlobalAssignBulk: isGlobalAssignBulk
                      }
                    })
                  }
                >
                  {">"}
                </p>
              ) : userFac?.reportRole === "maker" ? (
                facFilter !== "ALL" && (
                  <p
                    className="sub-heading-text cursor-pointer"
                    style={{ fontSize: "16px" }}
                    onClick={() =>
                      navigate(`${userFac?.reportRole === "maker" ? "/questions" : "/approvequestions"}`, {
                        state: {
                          qId: _questionId,
                          section,
                          reportId,
                          facId: facFilterTwo,
                          subSection: subtitle,
                          reportFyYear: reportFyYear,
                          indicator: indicator,
                          principle: principle
                        }
                      })
                    }
                  >
                    {">"}
                  </p>
                )
              ) : (
                <p
                  className="sub-heading-text cursor-pointer"
                  style={{ fontSize: "16px" }}
                  onClick={() => {
                    navigate(`${userFac?.reportRole === "maker" ? "/questions" : "/approvequestions"}`, {
                      state: {
                        qId: _questionId,
                        section,
                        reportId,
                        facId: facFilterTwo,
                        subSection: subtitle,
                        reportFyYear: reportFyYear,
                        indicator: indicator,
                        principle: principle
                      }
                    });
                  }}
                >
                  {">"}
                </p>
              )}
            </div>
          </div>
          <div
            ref={contentRef}
            className="accordion__content"
            style={
              isOpen
                ? // ? { height: contentRef.current.scrollHeight + 'px' }
                  { height: "486px" }
                : { height: "0px" }
            }
          >
            <div className="accordion__text">
              <div className="d-flex justify-content-between">
                <div>
                  <p className="card-type-title" style={{ fontSize: "13px" }}>
                    Select contributors
                  </p>
                  <div className="d-flex gap-2 align-items-center">
                    <img src="images/BRSR/info-icon.svg" alt="info-icon" width={18} />
                    <p className="sub-heading-text">
                      For each question, only one maker, reviewer/approver from the same facility has to be assigned
                    </p>
                  </div>
                </div>
                <div className="d-flex gap-2">
                  <Select
                    value={facFilter}
                    placeholder="Select Facility"
                    optionFilterProp="facility"
                    size="medium"
                    onChange={(newValue) => setFacFilter(newValue)}
                    className="br-8"
                  >
                    {userFac?.userGroup?.name === "Admin" && (
                      <Select.Option value={"ALL"}>All Facilities</Select.Option>
                    )}
                    {userFac?.facilities?.map((item, ind) => (
                      <Select.Option key={ind} value={item._id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                  <PrimaryButton
                    button
                    type="button"
                    style={{ height: "30px" }}
                    onClick={handleSubmit}
                    disabled={!manualChecked}
                  >
                    Done
                  </PrimaryButton>
                </div>
              </div>
            </div>
            <div className="grid-container">
              {contributors?.map((item) => (
                <>
                  {item.role && (
                    <SelectMember
                      bulk={false}
                      checkDisabled={checkDisabled}
                      handleChecked={handleChecked}
                      facility={item.facilityName}
                      members={members}
                      name={item.name}
                      email={item.email}
                      designation={item.designation}
                      department={item.department}
                      role={item.role}
                      facId={item.facilityId}
                      userId={item._id}
                    />
                  )}
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BRSRAccordion;
