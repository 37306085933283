export default function findChangedFields(prevState, currentState) {
  const changedFields = {};

  Object.keys(currentState).forEach((key) => {
    if (prevState[key] !== currentState[key]) {
      changedFields[key] = {
        prevValue: prevState[key],
        currentValue: currentState[key]
      };
    }
  });

  return changedFields;
}

// function stringToColor(string) {
//   let hash = 0;
//   let i;

//   /* eslint-disable no-bitwise */
//   for (i = 0; i < string.length; i += 1) {
//     hash = string.charCodeAt(i) + ((hash << 5) - hash);
//   }

//   let color = "#";

//   for (i = 0; i < 3; i += 1) {
//     const value = (hash >> (i * 8)) & 0xff;
//     color += `00${value.toString(16)}`.slice(-2);
//   }
//   /* eslint-enable no-bitwise */

//   return color;
// }

export function stringAvatar(name) {
  return {
    children: `${name?.split(" ")[0][0]}${name?.split(" ")[1][0]}`
  };
}

export function capitalizeFirstLetter(str) {
  if (typeof str !== "string" || str.length === 0) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const removeOnlyFacilityIdObjects = (arr) => {
  return arr.filter((obj) => !(Object.keys(obj).length === 1 && obj.hasOwnProperty("facilityId")));
};

export function isNestedObjectEmpty(obj) {
  if (typeof obj !== "object" || obj === null) return false;

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];

      if (typeof value === "object" && !isNestedObjectEmpty(value)) {
        return false;
      }
      if (value !== null && value !== undefined && value !== "") {
        return false;
      }
    }
  }
  return true;
}

// export const getRandomColor = (colors) => colors[Math.floor(Math.random() * colors.length)];
export const getRandomColor = (colors, ind) => colors[ind % colors.length];