import CloseIcon from "@mui/icons-material/Close";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Typography } from "@mui/material";
// import Accordion from "@mui/material/Accordion";
// import AccordionDetails from "@mui/material/AccordionDetails";
// import AccordionSummary from "@mui/material/AccordionSummary";
// import { Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useLazyGetEvidenceDataQuery, usePostEvidenceDataMutation } from "../../state/api";
import { apiUrl } from "../ApiUrl";
import CustomModal from "../Modal/Modal";
import { useSnackbar } from "../snackbar/SnackbarContext";
import "./evidence.scss";
import AlertIcon from "./icons/alert-circle.svg";
import CSVIcon from "./icons/csv.svg";
import DeleteIcon from "./icons/delete-icon-gray.svg";
// import EvidenceHoverIcon from "./icons/evidence-icon-hover.svg";
// import EvidenceIcon from "./icons/evidence-icon.svg";
import PDFIcon from "./icons/pdf.svg";
import PNGIcon from "./icons/file-attachment-2.svg";
import PreviewIcon from "./icons/preview-icon.svg";
import "../../pages/BRSRNew/CreateReport/Steps/Steps.scss";

export default function EvidenceComponent({ data, allData, dispatch, setPageWiseValue, name, disabled }) {
  const [openModal, setOpenModal] = useState(false);
  const [remarks, setRemarks] = useState("");
  const [error, setError] = useState(false);
  const [files, setFiles] = useState([]);
  const [reportId, setReportId] = useState();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { openSnackbar } = useSnackbar();
  const section = name?.split(".")[0];
  const field = name?.split(".")[1];
  const [getEvidence, resultEvidence] = useLazyGetEvidenceDataQuery();
  const [postEvidence] = usePostEvidenceDataMutation();
  const [showAlert, setShowAlert] = useState(false);
  const [currentItem, setCurrentItem] = useState();
  const [isHovering, setIsHovering] = useState(false);
  const uploadIcon = "/EvidenceButton/file attachment 1.svg";

  const handleDeleteModal = (item) => {
    setCurrentItem(item);
    setDeleteModalOpen(true);
  };

  const handleFileDelete = () => {
    const items = files.filter((file) => currentItem.name != file.name);
    setFiles(items);
    setDeleteModalOpen(false);
    setOpenModal(false);
  };

  const handleRemarks = (event) => [setRemarks(event.target.value)];

  const handleSubmit = async () => {
    if (remarks?.trim().length <= 0 || !remarks) {
      setError(true);
      openSnackbar("Remarks field is required", "warning");
      return;
    } else {
      setError(false);
    }

    const body = {
      report: data.reportId,
      question: data.qId,
      facility: data.facId,
      files: files.map((file) => ({
        location: file.location,
        name: file.name
      })),
      remarks: remarks
    };

    try {
      let response = await postEvidence(body);
      if (response?.data?.success) {
        getEvidence({
          reportId: data.reportId,
          questionId: data.qId,
          facilityId: data.facId
        });
        setReportId(response.data._id);
        openSnackbar("Evidence added successfully !", "success");
        setOpenModal(false);
      } else {
        openSnackbar(response.data.message, "warning");
      }
      return;
    } catch (err) {
      console.log(err);
      openSnackbar(err.message);
    }
  };

  const handlePreview = (url) => {
    window.open(url);
  };

  const handleFileIcon = ({ name }) => {
    const ext = name.split(".")[1];
    let icon;
    switch (ext) {
      case "png": {
        icon = PNGIcon;
        break;
      }
      case "csv": {
        icon = CSVIcon;
        break;
      }
      case "xlsx": {
        icon = CSVIcon;
        break;
      }
      case "pdf": {
        icon = PDFIcon;
        break;
      }
      default: {
        icon = PNGIcon;
        break;
      }
    }
    return icon;
  };

  useEffect(() => {
    setReportId(resultEvidence?.data?.data?.[0]?._id);
    setRemarks(resultEvidence?.data?.data?.[0]?.remarks);
    setFiles(resultEvidence?.data?.data?.[0]?.files);
  }, [resultEvidence?.data?.data?.[0]]);

  useEffect(() => {
    if (reportId && allData) {
      let newData = JSON.parse(JSON.stringify(allData));
      newData[section][field] = reportId;
      dispatch(setPageWiseValue(newData));
    }
  }, [reportId]);

  useEffect(() => {
    if (openModal) {
      getEvidence({
        reportId: data.reportId,
        questionId: data.qId,
        facilityId: data.facId
      });
    }
  }, [data, openModal]);

  useEffect(() => {
    if (!openModal) {
      setError(false);
      setShowAlert(false);
    }
  }, [openModal]);

  const FileItem = ({ item }) => {
    return (
      <div
        style={{
          backgroundColor: "white",

          marginBottom: "0.5rem",
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        <ReactTooltip id={"modal-info-year"} style={{ zIndex: 9991 }} place="top-start" />

        <DeleteModal
          openModal={deleteModalOpen}
          setModalOpen={setDeleteModalOpen}
          currentItem={currentItem}
          handleFileDelete={handleFileDelete}
        />

        <div style={{ display: "flex", width: "100%" }}>
          <img src={handleFileIcon({ name: item.name })} />
          <div
            style={{ marginTop: "auto", marginBottom: "auto", marginLeft: ".5rem", width: "100%" }}
            className="subtitle-2"
          >
            {item.name}
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div data-tooltip-id={"modal-info-year"} data-tooltip-html={`Click this icon to view file.`}>
            <div
              className="cursor-pointer"
              onClick={() => {
                handlePreview(item.location);
              }}
              style={{ marginRight: "10px" }}
            >
              <img src={PreviewIcon} width="30px" height="30px" />
            </div>
          </div>

          <img
            src={DeleteIcon}
            className="cursor-pointer "
            onClick={() => {
              handleDeleteModal(item);
            }}
            width="24"
            height="24"
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <CustomModal modalOpen={openModal} setModalOpen={setOpenModal} outSideClose={true}>
        <Box>
          <div style={{ marginBottom: "1rem", display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6" style={{ fontWeight: "500" }}>
              Upload Evidence
            </Typography>
            {/* <img src={} /> */}
            <div className="cursor-pointer" onClick={() => setOpenModal(false)}>
              <CloseIcon />
            </div>
          </div>

          <div
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderWidth: "2px",
              borderRadius: "1rem",
              borderStyle: "dashed",
              borderColor: "#cbd5e1",
              padding: "9px",
              minWidth: "346px"
            }}
          >
            <UploadBox files={files} setFiles={setFiles} openSnackbar={openSnackbar} setShowAlert={setShowAlert} />
          </div>

          {files && files.length > 0
            ? files.map((item) => (
                <div
                  style={{
                    borderRadius: "6px",
                    border: "1px solid var(--Neutral-30, #E2E2EA)",
                    background: "var(--White-100, #FFF)",

                    padding: "16px",
                    marginTop: "16px"
                  }}
                >
                  <FileItem item={item} key={item.name} />
                </div>
              ))
            : ""}

          {showAlert && (
            <div className="mt-3 d-flex align-items-center w-100">
              <div className="file-error-box">
                <img src={AlertIcon} />
                Error: File limit exceeds 10 mb.
              </div>
            </div>
          )}
          <div style={{ marginBottom: "1rem" }} className="mt-3">
            <p className="subtitle-1">
              Remarks<span style={{ color: "red" }}>*</span>
            </p>
            <div className="form-textfield-container">
              <div className="custom-textfield">
                <textarea
                  id="foreword"
                  style={{ borderColor: error ? "red" : "" }}
                  rows="4"
                  placeholder="Write your foreword here"
                  value={remarks}
                  onChange={handleRemarks}
                  className={`w-100`}
                  onFocus={() => setError(false)}
                  required={true}
                />
              </div>
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <button className="btn btn-success" style={{ width: "100%" }} onClick={handleSubmit}>
              Done
            </button>
          </div>
        </Box>
      </CustomModal>

      {!disabled && (
        <div
          className="assignQuestion cursor-pointer d-flex align-items-baseline gap-1 justify-content-center"
          style={{ background: "#F9F9F9", border: "#E1FDE1", width: "fit-content", padding: "5px 8px 5px 10px" }}
          onClick={() => setOpenModal(true)}
        >
          <div>
            <img src="/images/icons/blue-upload.svg" alt="Upload Icon" />
          </div>
          <p className="mb-0 text-nowrap" style={{ fontSize: "12px", color: "#1F93EF" }}>
            Upload evidence
          </p>
        </div>
      )}
    </>
  );
}

function UploadBox({ files, setFiles, openSnackbar, setShowAlert }) {
  const inputRef = useRef(null);

  const handleClick = () => {
    inputRef.current.click();
  };

  const handleFileChange = async (event) => {
    if (event.target.files[0].size >= 10000000) {
      setShowAlert(true);
    } else {
      setShowAlert(false);
      let formData = new FormData();
      formData.append("files", event.target.files[0]);
      const url = `${apiUrl}/evidence/upload`;
      try {
        let response = await fetch(url, {
          method: "POST",
          credentials: "include",
          body: formData
        });
        response = await response.json();
        if (response.success) {
          if (files) {
            let newfiles = [...files];
            newfiles.push(response.data[0]);
            setFiles(newfiles);
          } else {
            setFiles(response.data);
          }
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <>
      <div onClick={handleClick} className="cursor-pointer d-flex flex-column align-items-center gap-2 p-3">
        <div
          className="upload-icon"
          style={{
            display: "flex",
            width: "44px",
            height: "44px",
            padding: "9px",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "50px",
            background: "#f2f3f7"
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="20" viewBox="0 0 17 20" fill="none">
            <path
              d="M3 19H14C15.1046 19 16 18.1046 16 17V7.34434C16 6.74643 15.7325 6.17988 15.2708 5.79997L9.99122 1.45563C9.63323 1.16106 9.18402 1 8.72042 1H3C1.89543 1 1 1.89543 1 3V17C1 18.1046 1.89543 19 3 19Z"
              stroke="#16161E"
              stroke-opacity="0.88"
              stroke-width="1.4"
              stroke-linejoin="round"
            />
            <path
              d="M9 0.5V4C9 5.10457 9.89543 6 11 6H15.5"
              stroke="#16161E"
              stroke-opacity="0.88"
              stroke-width="1.4"
              stroke-linejoin="round"
            />
            <path
              d="M9.35203 10.902L6.67601 13.4497C6.18337 13.9188 6.18337 14.6792 6.67601 15.1482C7.16866 15.6173 7.96738 15.6173 8.46002 15.1482L11.136 12.6005C12.1213 11.6625 12.1213 10.1416 11.136 9.20354C10.1508 8.26549 8.5533 8.26549 7.56802 9.20354L4 12.6005"
              stroke="#16161E"
              stroke-opacity="0.88"
              stroke-width="1.4"
            />
          </svg>
        </div>
        <div>
          <div>
            <p className="subtitle-2 text-center mb-0">Click to Upload</p>
            <p className="caption-1-Regular   text-center mb-0"> (Max. File size: 25 MB)</p>
          </div>
        </div>
        <input type="file" ref={inputRef} onChange={handleFileChange} style={{ display: "none" }} />
      </div>
    </>
  );
}

function DeleteModal({ openModal, setModalOpen, handleFileDelete, currentItem }) {
  return (
    <CustomModal modalOpen={openModal} setModalOpen={setModalOpen} outSideClose={true}>
      <Box sx={{ width: 500 }}>
        <div style={{ textAlign: "center" }}>
          <Typography variant="h5" sx={{ marginTop: ".5rem" }}>
            Are you sure you want to delete this file?
          </Typography>
          <div style={{ fontSize: 14, color: "#9C9C9C", marginTop: ".5rem" }}>
            {currentItem?.name} will be deleted from the uploaded files list
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center", gap: "1rem", marginTop: "1rem" }}>
          <button className="btn btn-outline-success" style={{ width: 30 }} onClick={() => setModalOpen(false)}>
            Cancel
          </button>
          <button className="btn btn-danger" style={{ width: 30 }} onClick={handleFileDelete}>
            Delete
          </button>
        </div>
      </Box>
    </CustomModal>
  );
}
