export const principlesMap = {
  principleOne: "Principle 1",
  principleTwo: "Principle 2",
  principleThree: "Principle 3",
  principleFour: "Principle 4",
  principleFive: "Principle 5",
  principleSix: "Principle 6",
  principleSeven: "Principle 7",
  principleEight: "Principle 8",
  principleNine: "Principle 9"
};

export const sectionMap = {
  SectionA: "Section A",
  SectionB: "Section B",
  SectionC: "Section C"
};

export const indicatorMap = {
  essential: "Essential Indicators",
  leadership: "Leadership Indicators"
};

export const facilityAssignmentMap = {
  single: "Single Facilities",
  multi: "Multiple Facilities"
};

export const makerAssignedEmptyScreenMessages = [
  {
    key: "isAnswered",
    message: `No answered questions yet!`,
    subMessage: "You haven’t submitted answers for any questions. Start answering questions assigned to you to proceed."
  },
  {
    key: "isUnanswered",
    message: `No pending questions to answer!`,
    subMessage: "All assigned questions have been answered. Review submitted answers or check for new questions."
  },
  {
    key: "isApproved",
    message: `No approved questions yet!`,
    subMessage: "There are no answers approved by the approver yet. Wait for their review or follow up as needed."
  },
  {
    key: "isRejected",
    message: `No rejected questions yet!`,
    subMessage:
      "Good news! None of your submitted answers have been rejected. Continue contributing high-quality responses."
  },
  {
    key: "isPendingApproval",
    message: `No questions pending approval!`,
    subMessage:
      "You have no answers currently awaiting approval. Check if the approver has taken action or review your submitted answers."
  }
];

export const adminAssignedEmptyScreenMessages = [
  {
    key: "isUnanswered",
    message: `No answered questions yet!`,
    subMessage:
      "No answers have been submitted by contributors yet. Ensure contributors are assigned and working on their questions."
  },
  {
    key: "isApproved",
    message: `No approved questions yet!`,
    subMessage: "No answers have been approved by the approver yet. Once done, you can proceed with final acceptance."
  },
  {
    key: "isRejected",
    message: `No rejected questions yet!`,
    subMessage:
      "No answers have been rejected by the approver in this section. Check other statuses or monitor progress."
  },
  {
    key: "isPendingApproval",
    message: `No questions pending approval!`,
    subMessage:
      "There are no answers awaiting approval by the approver in this section. Wait for the review process to be completed."
  },
  {
    key: "isAccepted",
    message: `No accepted questions yet!`,
    subMessage:
      "You haven’t accepted any answers in this section yet. Review the approved answers to take the next step."
  },
  {
    key: "notAccepted",
    message: `No unaccepted questions yet!`,
    subMessage:
      "None of the approved answers have been marked as not accepted in this section. Proceed to finalise pending answers."
  },
  {
    key: "overdue",
    message: "No overdue unassigned questions!",
    subMessage: "All questions were assigned before the report's due date. Check other statuses to ensure completeness."
  },
  {
    key: "notAssigned",
    message: "All questions are assigned!",
    subMessage:
      "No unassigned questions are remaining. Review assigned questions for updates or make changes if required."
  },
  {
    key: "isAssigned",
    message: "No assigned questions yet!",
    subMessage:
      "You haven’t assigned any questions to contributors. Select questions and assign them to proceed with the report workflow."
  }
];

export const approverAssignedEmptyScreenMessages = [
  {
    key: "isUnanswered",
    message: `No unanswered questions!`,
    subMessage:
      "All assigned questions have been answered by the makers. Review approved or pending questions for updates."
  },
  {
    key: "isAnswered",
    message: `No answered questions yet!`,
    subMessage: "No questions have been answered by the makers for your review. Check back later for submitted answers."
  },
  {
    key: "isApproved",
    message: `No approved questions yet!`,
    subMessage:
      "You haven't approved any answers in this section yet. Start reviewing pending answers submitted by makers."
  },
  {
    key: "isRejected",
    message: `No rejected questions yet!`,
    subMessage: "You haven't rejected any answers in this section. Review pending answers to decide."
  },
  {
    key: "isPendingApproval",
    message: `No questions pending approval!`,
    subMessage: "You have no answers awaiting your approval. Check if makers have submitted answers for your review."
  },
  {
    key: "isAccepted",
    message: `No accepted questions yet!`,
    subMessage: "The admin has not accepted any approved answers yet. Check the status or follow up if needed."
  },
  {
    key: "notAccepted",
    message: `No unaccepted questions yet!`,
    subMessage:
      "None of the approved answers have been marked as not accepted by the admin. Proceed with other reviews as needed."
  }
];

export const sections = [
  { name: "All Sections", tag: "A + B + C" },
  {
    name: "Section A",
    tag: "General Disclosures",
    subsections: [
      "I. Details of the listed entity",
      "II. Products/Services",
      "III. Operations",
      "IV. Employees",
      "V. Holding, Subsidiary and Associate Companies (including joint ventures)",
      "VI. CSR Details",
      "VII. Transparency and Disclosures Compliances"
    ]
  },
  {
    name: "Section B",
    tag: "Management & Process Disclosure",
    subsections: ["Policy and management processes", "Governance, leadership and oversight"]
  },
  {
    name: "Section C",
    tag: "Principle-Wise Performance Disclaimer",
    subsections: [
      "Principle 1",
      "Principle 2",
      "Principle 3",
      "Principle 4",
      "Principle 5",
      "Principle 6",
      "Principle 7",
      "Principle 8",
      "Principle 9"
    ],
    indicator: ["Essential Indicators", "Leadership Indicators"]
  }
];

export const quarterMap = {
  q1: "Quarter 1",
  q2: "Quarter 2",
  q3: "Quarter 3",
  q4: "Quarter 4"
};

