import React, { useState, useEffect, useRef, memo } from "react";
import "./CustomSectionDropdown.scss";

const CustomSectionDropdown = ({ sections, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedSubsection, setSelectedSubsection] = useState(null);
  const [selectedIndicator, setSelectedIndicator] = useState(null);
  const [selectedValue, setSelectedValue] = useState("");

  const [hoverSection, setHoverSection] = useState(null);
  const [hoverSubsection, setHoverSubsection] = useState(null);

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
        setHoverSection(null);
        setHoverSubsection(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSectionClick = (section) => {
    setSelectedSubsection(null);
    setSelectedIndicator(null);
    setSelectedSection(section);
    setSelectedValue(`${section.name}`);
    onChange(`${section.name}`);

    if (section.name === "All Sections") {
      setIsOpen(false);
      setSelectedSection(null);
    }
  };

  const handleSubsectionClick = (section, subsection) => {
    setSelectedIndicator(null);
    setSelectedSection(section);
    setSelectedSubsection(subsection);
    setSelectedValue(`${section.name} / ${subsection}`);
    onChange(`${section.name} / ${subsection}`);

    if (section.name !== "Section C") {
      setIsOpen(false);
    }
  };

  const handleIndicatorClick = (section, subsection, indicator) => {
    setSelectedSection(section);
    setSelectedSubsection(subsection);
    setSelectedIndicator(indicator);
    setSelectedValue(`${section.name} / ${subsection} / ${indicator}`);
    onChange(`${section.name} / ${subsection} / ${indicator}`);
    setIsOpen(false);
  };

  const handleMouseOverSection = (section) => {
    if (section.name !== "All Sections") {
      setHoverSection(section);
      setHoverSubsection(null);
    } else {
      setHoverSection(null);
    }
  };

  const handleMouseOverSubsection = (subsection) => {
    if (selectedSection?.name === "Section C" || hoverSection?.name === "Section C") {
    setHoverSubsection(subsection);
    }
  };

  return (
    <div className="custom-dropdown-container" ref={dropdownRef}>
      <div className="dropdown-trigger" onClick={() => setIsOpen(!isOpen)}>
        <span style={{ color: "#16161EB2" }}>{selectedValue || "Select section/sub section"}</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path
            d="M16 11L12 15L8 11"
            stroke="#16161E"
            stroke-opacity="0.7"
            stroke-width="1.8"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>

      {isOpen && (
        <div className="dropdown-menu">
          <div className="sections-list">
            {sections?.map((section) => (
              <div
                key={section?.name}
                className={`section-item ${
                  selectedSection?.name === section.name
                    ? `active ${section.name.replace(/\s+/g, "-").toLowerCase()}`
                    : ""
                }`}
                onClick={() => handleSectionClick(section)}
                onMouseMove={() => handleMouseOverSection(section)}
              >
                <div className="section-item-icon">
                  {section.name !== "All Sections" ? (
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M14 16L10 12L14 8"
                        stroke="#16161E"
                        stroke-opacity="0.35"
                        stroke-width="1.8"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  ) : (
                    <span style={{ width: "24px" }}></span>
                  )}
                </div>
                <div className="section-item-text">
                  <div className="section-name">{section?.name}</div>
                  <div className="section-tag">{section?.tag}</div>
                </div>
              </div>
            ))}
          </div>
          {(selectedSection || hoverSection) && selectedSection?.name !== "All Sections" ? (
            <div className="subsections-list">
              {(selectedSection || hoverSection)?.subsections?.map((subsection) => (
                <div
                  key={subsection}
                  className={`subsection-item ${
                    selectedSubsection
                      ? selectedSubsection === subsection
                        ? `active ${selectedSection.name.replace(/\s+/g, "-").toLowerCase()}`
                        : ""
                      : ""
                  }`}
                  onClick={() => handleSubsectionClick(selectedSection || hoverSection, subsection || hoverSubsection)}
                  onMouseMove={() => handleMouseOverSubsection(subsection)}
                >
                  {(selectedSection || hoverSection)?.name === "Section C" && (
                    <div className="subsection-item-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                          d="M14 16L10 12L14 8"
                          stroke="#16161E"
                          stroke-opacity="0.35"
                          stroke-width="1.8"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  )}
                  <span className="subsection-name" style={{ color: "#16161E" }}>
                    {subsection}
                  </span>
                </div>
              ))}
            </div>
          ) : null}
          {(hoverSubsection || selectedSubsection) && (selectedSection || hoverSection)?.name === "Section C" ? (
            <div className="subsections-list">
              {(selectedSection || hoverSection)?.indicator?.map((indicator) => (
                <div
                  key={indicator}
                  className={`subsection-item ${
                    selectedIndicator
                      ? selectedIndicator === indicator
                        ? `active ${selectedSection.name.replace(/\s+/g, "-").toLowerCase()}`
                        : ""
                      : ""
                  }`}
                  onClick={() =>
                    handleIndicatorClick(
                      selectedSection || hoverSection,
                      selectedSubsection || hoverSubsection,
                      indicator
                    )
                  }
                >
                  <span className="subsection-name" style={{ color: "#16161E" }}>
                    {indicator}
                  </span>
                </div>
              ))}
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default CustomSectionDropdown;
