import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  analysisName: "",
  region: null,
  industry: null,
  subIndustry: [],
  year: null,
  analysisId: "",
  baseCompany: null
};

const peerAnalysisSlice = createSlice({
  name: "createPeer",
  initialState,
  reducers: {
    setAnalysisName(state, action) {
      state.analysisName = action.payload;
    },
    setAnalysisId(state, action) {
      state.analysisId = action.payload;
    },
    setRegion(state, action) {
      state.region = action.payload;
    },
    setIndustry(state, action) {
      state.industry = action.payload;
    },
    setSubIndustry(state, action) {
      state.subIndustry = action.payload;
    },
    setBaseCompany(state, action) {
      state.baseCompany = action.payload;
    },
    setYear(state, action) {
      state.year = action.payload;
    },
    resetState() {
      return initialState;
    }
  }
});

export const { setAnalysisName, setRegion, setSubIndustry, setIndustry, setYear, resetState, setAnalysisId, setBaseCompany } =
  peerAnalysisSlice.actions;
export default peerAnalysisSlice.reducer;
